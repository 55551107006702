<template>
  <VueSlickCarousel
    class="carousel--wrapper project--slick"
    v-bind="settings"
    @beforeChange="beforeChange"
    @afterChange="afterChange"
  >
    <div v-for="(content, index) in contents" :key="index" class="carousel--slide">
      <!--      <card-project-partner-->
      <!--        v-if="category === 'projectpartner'"-->
      <!--        :projectPartner="content"-->
      <!--        :allowMultiImage="false"-->
      <!--      />-->
      <!--      <card-project :premier="premier" v-else :project="content" :allowMultiImage="false" />-->
      <card-listing
        :key="content.uuid"
        :premier="premier"
        :listing="content"
        :allowMultiImage="false"
        v-if="type === listingTypeEnum.SECONDARY"
      />
      <card-project
        :key="content.uuid"
        :premier="premier"
        :project="content"
        :allowMultiImage="false"
        v-else
      />
    </div>
    <template #prevArrow>
      <div class="custom-prev--arrow projects-prev--arrow"></div>
    </template>
    <template #nextArrow>
      <div class="custom-next--arrow projects-next--arrow"></div>
    </template>
  </VueSlickCarousel>
</template>

<script>
import { mapState } from 'vuex';

const CardProject = () => import('@/components/utils/card-project');
const CardListing = () => import('@/components/utils/card-listing');

export default {
  components: {
    CardProject,
    CardListing,
  },
  props: {
    contents: {
      default: [],
      require: true,
    },
    category: {
      default: null,
    },
    premier: {
      default: false,
    },
    slidesPerRow: {
      default: 4,
    },
    type: {
      default: null,
    },
  },
  data: () => ({
    dragging: false,
    settings: {
      dots: false,
      edgeFriction: 0.35,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesPerRow: 4,
      autoplay: true,
      autoplaySpeed: 5000,
      // lazyLoad: 'progressive', // Ini kalo barengan multiple rows jadi gak bisa slide
      draggable: false,
      responsive: [
        {
          breakpoint: 1365,
          settings: {
            slidesPerRow: 4,
          },
        },
        {
          breakpoint: 959,
          settings: {
            slidesPerRow: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesPerRow: 2,
          },
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      listingTypeEnum: (state) => state.v2.masters.listingTypeEnum,
    }),
  },
  mounted() {
    this.settings.slidesPerRow = this.slidesPerRow;
    this.settings.responsive[0].slidesPerRow = this.slidesPerRow;
  },
  methods: {
    beforeChange() {
      this.dragging = true;
    },
    afterChange() {
      this.dragging = false;
    },
    clickHandler(e) {
      if (this.dragging) {
        e.preventDefault();
      }
    },
  },
};
</script>
