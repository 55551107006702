<template>
  <section class="home--about-container mb-4-5" style="background-color: white">
    <div class="container">
      <div class="title--text mb-4-5">
        {{ $t('home.testimony.title') }}
      </div>
      <div class="content--coverage mb-4-5">
        <client-only>
          <slick-testimony />
        </client-only>
      </div>
    </div>
  </section>
</template>

<script>
import SlickTestimony from '@/components/home/partials/slick-testimony';
export default {
  components: {
    SlickTestimony,
  },
  computed: {},
  methods: {},
};
</script>
