<template>
  <section class="home--about-container home--consultation-container">
    <div class="container">
      <h2 class="title--text mb-4-5">
        {{ $t('home.consultation.title') }}
      </h2>
      <div class="content mb-4-5">
        <div class="content--text" v-html="$t('home.consultation.message1')"></div>
      </div>
      <div class="see--all">
        <button class="btn" @click="showModal">
          {{ $t('home.consultation.button') }}
        </button>
      </div>
      <div class="content mb-4" style="margin-top: 40px">
        <div class="content--text" v-html="$t('helpDesk.helpFindResidence.question')"></div>
      </div>
      <div class="see--all">
        <router-link to="/bantu-cari-properti" style="text-decoration: none">
          <button class="btn">
            {{ $t('helpDesk.helpFindResidence.title') }}
          </button>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  computed: {},
  methods: {
    showModal() {
      this.$modal.show('modal--consultation');
    },
  },
};
</script>
