<template>
  <div
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  >
    <section
      class="home--projects-container"
      :class="useBackground ? 'home--project-background' : ''"
      v-if="listProject && listProject.length > 0"
    >
      <div class="container">
        <div class="d-flex justify-content-between align-items-center mb-4-5">
          <h2 class="title--text">
            {{ title }}
          </h2>
          <div class="see--all">
            <router-link class="btn btn-primary btn--all mr-3 my-auto col-auto" :to="seeAllUrl">{{
              $t('home.seeAll')
            }}</router-link>
          </div>
        </div>
        <div class="slider">
          <slick-projects
            :category="category"
            :premier="false"
            :contents="listProject"
            :type="isSecondary ? listingTypeEnum.SECONDARY : listingTypeEnum.PRIMARY"
          />
        </div>
      </div>
    </section>
    <div v-else></div>
  </div>
</template>

<script>
import SlickProjects from './partials/slick-projects';
import { mapState } from 'vuex';
export default {
  props: {
    useBackground: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    seeAllUrl: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      listProject: [],
    };
  },
  components: { SlickProjects },
  computed: {
    ...mapState({
      listingTypeEnum: (state) => state.v2.masters.listingTypeEnum,
    }),
    isSecondary() {
      return (
        this.category === 'listing' ||
        this.category === 'house-secondary' ||
        this.category === 'apartment-secondary'
      );
    },
  },
  methods: {
    visibilityChanged(value) {
      if (value) {
        this.getProjects();
      }
    },
    async getProjects() {
      let query = {};
      query.paginate = 6;

      let homePaginate = 8;
      if (this.$device && this.$device.isMobile) homePaginate = 5;
      let url = this.isSecondary ? '/api/v2/search_listing' : '/api/search';
      switch (this.category) {
        case 'subsidized-house': {
          query.paginate = homePaginate;
          query.property_type_id = 1;
          query.subsidized = true;
          break;
        }
        case 'house': {
          query.paginate = homePaginate;
          query.property_type_id = 1;
          query.listing_type_id = 1;
          break;
        }
        case 'apartment': {
          query.paginate = homePaginate;
          query.property_type_id = 2;
          query.listing_type_id = 1;
          break;
        }
        case 'house-secondary': {
          query.paginate = homePaginate;
          query.property_type_id = 1;
          query.listing_type_id = 2;
          break;
        }
        case 'apartment-secondary': {
          query.paginate = homePaginate;
          query.property_type_id = 2;
          query.listing_type_id = 2;
          break;
        }
        case 'listing': {
          query.paginate = homePaginate;
          query.listing_type_id = 2;
          break;
        }
      }

      const { data } = await this.$http.get(url, {
        params: query,
      });
      this.listProject = data.data.data;
      console.log('result: ', data.data.data);
    },
  },
};
</script>
